export const getInternalLinkPath = reference => {
  if (reference && reference.content && reference.content.main) {
    if (reference.content.main.slug.current === "homepage") {
      return `/`
    } else {
      return `/${reference.content.main.slug.current}`
    }
  } else {
    return `/`
  }
}
