import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import { Image } from "src/components/image"
import { BlockCopy } from "src/components/block-copy"
import styles from "./about.module.css"

export const About = ({ content }) => {
  return (
    <div className={styles.about}>
      <Image
        className={styles.portrait}
        imageId={content.mainImage.asset._id}
        width={960}
        alt="Home Video"
      />
      <BlockCopy className={styles.body} copy={content.body} />
    </div>
  )
}
