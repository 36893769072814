import React from "react"
import { graphql } from "gatsby"
import About from "src/templates/about"

export const query = graphql`
  query AboutQuery {
    page: sanityAbout(_id: { regex: "/(drafts.|)about/" }) {
      title
      slug {
        current
      }
      mainImage: _rawMainImage(resolveReferences: { maxDepth: 4 })
      body: _rawBody
    }
  }
`

const AboutPage = ({ data, location }) => {
  return <About location={location} pageContext={data.page} />
}

export default AboutPage
