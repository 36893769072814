import React from "react"
import { Link } from "gatsby"
import { getInternalLinkPath } from "./getInternalLinkPath"

const ListSerializer = ({ type, children }) => {
  return type === "bullet" ? <ul>{children}</ul> : <ol>{children}</ol>
}

const ListItemSerializer = ({ children }) => {
  return <li>{children}</li>
}

export const Serializer = {
  list: ListSerializer,
  listItem: ListItemSerializer,
  marks: {
    tick: props => <span className="tick">{props.children}</span>,
    link: ({ mark, children }) => {
      const { blank, href } = mark
      return blank ? (
        <a href={href} target="_blank" rel="noopener">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      )
    },
    internalLink: ({ mark, children }) => {
      const path = getInternalLinkPath(mark.reference.link)
      return <Link to={path}>{children}</Link>
    },
  },
}
