import React from "react"
import { RevealOnMount } from "components/reveal-on-mount"
import SEO from "components/seo"
import { About as AboutContent } from "components/about"

const About = ({ location, pageContext }) => {
  return (
    <RevealOnMount location={location}>
      <SEO title="About" />
      <AboutContent content={pageContext} />
    </RevealOnMount>
  )
}

export default About
